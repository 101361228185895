import { HotspotActionType } from '../types/hotspot-action-type.type';
import { HotspotDisplayType } from '../types/hotspot-display-type.type';
import { HotspotCircle } from './hotspot-circle.interface';
import { HotspotEllipse } from './hotspot-ellipse.interface';
import { HotspotPolygon } from './hotspot-polygon.interface';
import { HotspotRect } from './hotspot-rect.interface';

export class Hotspot {
  id: string | number;
  /** Page number of the hotspot. */
  page: number;
  /** Path of the hotspot. */
  path: string;
  /** Color of the hotspot. */
  color: string;
  /** Display type of the hotspot. */
  display: HotspotDisplayType;
  /** Action of the hotspot. */
  action: Action;
  /** Internally used by the Hotspot Editor. */
  attributes?: HotspotRect | HotspotCircle | HotspotEllipse | HotspotPolygon;
}

/**
 * Hotspot action data.
 */
export class Action {
  id: string;
  /** Action type of the hotspot. */
  type: HotspotActionType;
  /** Target of the hotspot. */
  target: string;
  /** Title of the hotspot. */
  title?: string;
  /** Description of the hotspot. */
  description?: string;
  /** Width of the hotspot. */
  width?: number;
  /** Height of the hotspot. */
  height?: number;
  /** Whether or not autoplay for the hotspot target is enabled. */
  autoplay?: boolean;
  /** Article price. */
  price?: number;
  /** Custom hotspot attributes. */
  custom?: any;
  /** Width of the hotspot iFrame. */
  iFrameWidth?: number;
  /** Height of the hotspot iFrame. */
  iFrameHeight?: number;
  count?: number;

  constructor(data, i?){
    this.type = data['Type'] ? data['Type'].toLowerCase() : (window.localStorage.getItem('selectedActionType') ? window.localStorage.getItem('selectedActionType') : 'external');

    this.description = data['Description'];
    this.title = data['Title'] ?? 'Action ' + (i + 1);
    this.count = data['Count'] ?? 0;

    switch (this.type) {
      case 'external':
      case 'image':
      case 'iframe':
          this.target  = data['URL'];
        break;
      case 'video':
      case 'audio':
          this.target  = data['URL'];
          this.autoplay = data['AutoPlay'];
        break;
      case 'internal':
          this.target = data['Page number'].toString();
        break;
      case 'article':
          this.target = data['Article number'].toString();
          this.price =  data['Price'];
        break;
      case 'feature':
          this.target = data['Action'].toString();
        break;
      default:
        break;
    }
  }
}
